import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { APP_SETTINGS } from './configs/app-settings.config';
import { PermissionsService } from './core/services/permissions.service';
import './configs/array.config';
import { RouteHistory2Service } from './shared/routes/route-history2.service';
import { LoaderService } from './shared/components/loader/loader.service';
import { IdleUserService } from './shared/services/idle-user.service';
import { AuthService } from './core/services/auth-service.service';
import { MonitorService, TelemeteryNotification } from './shared/services/monitor.service';
import { WebVitalsService } from './shared/services/web-vitals.service';
import { NavigationEnd, Router } from '@angular/router';
import { FeatureFlagService } from './shared/services/feature-flag.service';
import { SessionManagerService } from './shared/services/session-manager.service';
import { FeatureFlagEnum } from './shared/enums/featureFlag.enum';
import { MatDialog } from '@angular/material';
import { SessionBlockAlertComponent, SessionBlockType } from './shared/components/session-block-alert/session-block-alert.component';
import { MixpanelService } from './shared/services/mixpanel.service';
import { BusinessEventEnum } from './shared/enums/business-event.enum';

// #region Constants

const SESSION_BLOCKED = {
  width: '515px',
  height: '265px',
  backdropClass: 'session-block-backdrop',
};
const EMAIL = 'Email';

// #endregion

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  constructor(private readonly translate: TranslateService, 
    private readonly _routerService: RouteHistory2Service,
    private readonly _permissions: PermissionsService, 
    private readonly _router: Router,
    public readonly loader: LoaderService,
    private readonly _idle: IdleUserService,
    private readonly _auth: AuthService,
    private readonly _monitor: MonitorService,
    private readonly _webVitals: WebVitalsService,
    private readonly sessionManager: SessionManagerService,
    private readonly dialog: MatDialog,
    private readonly ldFeatureFlag: FeatureFlagService,
    private readonly _mixpanel: MixpanelService) {

    this.translate.setDefaultLang(APP_SETTINGS.LANGUAGE.DEFAULT_LANGUAGE);
  }

  @HostListener('window:beforeunload') save() {
    this._routerService.saveHistoryState();
    this._permissions.savePermissions();
  }

  @HostListener('window:load') onload() {
    
    this._idle.start();
  }

  async ngOnInit(){

    //Start App Insigh monitor
    this._monitor.start(); 

    this.ldFeatureFlag.initialize();

    await this.authSessionSecurity();

    this._auth.loginListener(

      async (user)=>{
        
        this._idle.reset();
        this.afterUserLogin(user);
      }
    );

    this._router.events.subscribe(event => {

      if (event instanceof NavigationEnd) {
        
        if(event.url !== ''){

          this._webVitals.logWebVitals(event.url);
        }
      }
    });
  }

  /**
   * Action to do when after user is login.
   * 
   * @param user The email of the logged-in user.
   */
  afterUserLogin(user: string) {

    //Send to AppInsigh user
    this._monitor.logEvent(TelemeteryNotification.UserLogin, {
      userEmail: user
    });

    //Send to Mixpanel user login event
    this._mixpanel.trackEvent(BusinessEventEnum.LOGIN, { [EMAIL]: user });
  }

  /** 
   * Function to verify if the session is active in other browser instance
  */
  authSessionSecurity() {

    const flagSubscription = this.ldFeatureFlag.getFlagObservable(FeatureFlagEnum.SESSION_VERIFICATION).subscribe(
      async (flag) => {

        if (flag) {

          const isSessionActive = await this.sessionManager.activeSession().toPromise();

          if (isSessionActive) {

            this.dialog.open(SessionBlockAlertComponent, {
              width: SESSION_BLOCKED.width,
              height: SESSION_BLOCKED.height,
              disableClose: true,
              backdropClass: SESSION_BLOCKED.backdropClass,
              hasBackdrop: true,
              data: {
                type: SessionBlockType.BLOCKED
              }
            });
          }
        }
        flagSubscription.unsubscribe();
      }
    );
  }
}
