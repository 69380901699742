export enum FeatureFlagEnum {

  SEARCH_INSTANCES_API = 'frontend-search-instances-api',
  CREATE_INSTANCE_INSTANCES_API = 'web-create-instance-api',
  GET_INSTANCE_INSTANCES_API= 'web-get-instance-api',
  GET_CONTAINER_INSTANCES_API = 'web-get-container-api',
  PROCESS_INSTANCES_API = 'web-process-instance-api',
  PARTIAL_FUNCTION_INSTANCES_API = 'web-partial-function-instance-api',
  REMOVE_GET_INSTANCE_PROFILE = 'web-remove-request-get-instance-profile',
  INBOX_ASSIGNMENT_INSTANCES_API = 'web-inbox-assignment-instance-api',
  SAVE_ROTATED_IMAGE = 'web-save-rotated-image',
  AAD_SYNC_AGENTS = 'web-aad-sync-agents',
  SESSION_VERIFICATION = 'web-auth-session-verification',
  FOLDER_ALLOW_PARALLEL_UPDATE = 'web-folder-allow-parallel-update',
  AGENT_FOLDER_INFO_API = 'web-agent-folder-info-api',
  AGENT_PROFILE_PERMISSIONS_API = 'web-agent-profile-permissions-api',
  PARTIAL_SAVE_API = 'web-partial-save-api',
  DIGITAL_ONBOARDING_ADMIN_PANEL = 'web-digital-onboarding-admin-panel',
  REQUIRED_HINT = 'web-required-hint',
  INSTANCES_REASSIGNMENT = 'web-instances-reassignment',
  PRODUCTS_FORMS_ADMIN = 'web-products-forms-admin',
}